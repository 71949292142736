import React from 'react'
import Page from '../components/Page'
import SEO from '../components/seo'
import AllApplications from '../components/AllApplications'

const AllApplicationsPage = () => (
  <Page>
    <SEO
      title="Automated Lab Applications on the Opentrons OT-2"
      description="The OT-2 automates your PCR prep, NGS library prep, nucleic acid purification, ELISA, serial dilutions, DNA extraction,  MALDI spotting, &amp; 100s more."
    />
    <AllApplications />
  </Page>
)

export default AllApplicationsPage
