// @flow

import * as React from 'react'
import cx from 'classnames'

import {
  BLACK,
  BLUE,
  ORANGE,
  LIGHT_BLUE,
  LIGHT_CYAN,
  GRAY,
  GREEN,
  gtmCategory,
} from './all-applications-data'
import {AppLink} from '../../ui-components'

import * as styles from './AllApplications.module.css'

import type {ApplicationCardProps} from './all-applications-data'

type Props = {|
  ...ApplicationCardProps,
  active?: string,
  viewAll?: boolean,
|}

export default function ApplicationCard(props: Props) {
  const {header, slug, items, active, viewAll} = props
  return (
    <>
      {(active === slug || viewAll) && (
        <div id={slug}>
          {!(active === slug) && <h5 className={styles.card_h5}>{header}</h5>}
          <div className={styles.item_container}>
            {items.map((data, i) => {
              return (
                <div
                  className={cx(styles.app_card_container, {
                    [styles.black_border]: data.color === BLACK,
                    [styles.blue_border]: data.color === BLUE,
                    [styles.orange_border]: data.color === ORANGE,
                    [styles.light_blue_border]: data.color === LIGHT_BLUE,
                    [styles.light_cyan_border]: data.color === LIGHT_CYAN,
                    [styles.gray_border]: data.color === GRAY,
                    [styles.green_border]: data.color === GREEN,
                  })}
                  key={i}
                >
                  {data.workstation === true && (
                    <div
                      className={cx(styles.banner_container, {
                        [styles.black_bg]: data.color === BLACK,
                        [styles.blue_bg]: data.color === BLUE,
                        [styles.orange_bg]: data.color === ORANGE,
                        [styles.light_blue_bg]: data.color === LIGHT_BLUE,
                        [styles.light_cyan_bg]: data.color === LIGHT_CYAN,
                        [styles.gray_bg]: data.color === GRAY,
                        [styles.green_bg]: data.color === GREEN,
                      })}
                    >
                      <span className={styles.banner}>Workstation</span>
                    </div>
                  )}
                  <p className={styles.p}>{data.text}</p>
                  <div className={styles.link_container}>
                    <AppLink
                      trueLink={data.trueLink}
                      url={data.url}
                      gtmLabel={data.gtmLabel}
                      gtmCategory={gtmCategory}
                    >
                      {data.children} ›
                    </AppLink>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}
