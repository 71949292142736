// @flow

import * as React from 'react'
import cx from 'classnames'

import ApplicationCard from './ApplicationCard'

import * as styles from './AllApplications.module.css'

import {ALL_APPLICATIONS_CARD, LIST_ITEMS_DATA} from './all-applications-data'

export default function AllApplications() {
  const [viewAll, setViewAll] = React.useState(true)
  const [selectView, setSelectView] = React.useState('')
  const gtmCategory = 'allapps'

  const openView = slug => {
    if (slug) {
      setSelectView(slug)
      setViewAll(false)
    }
  }

  const openViewAll = () => {
    setViewAll(true)
    setSelectView('')
  }

  return (
    <>
      <div className={cx(styles.chat_iframe_container, styles.bg_color)}>
        <iframe
          className={styles.chat_iframe}
          src="https://drift-lp-77847098.drift.click/embed/208765/1353150"
          id="drift-embed-frame"
          height="100%"
          width="100%"
          frameBorder="0"
        />
      </div>
      <div className={styles.bg_color}>
        <div className={cx(styles.container, styles.application_type)}>
          <div className={styles.application_type_container}>
            <p className={styles.application_header}>Application type</p>
            <ul>
              {LIST_ITEMS_DATA.map((data, i) => {
                if (data.allView) {
                  return (
                    <li
                      key={i}
                      className={cx(styles.list_item, {
                        [styles.blue_text]: viewAll === data.allView,
                      })}
                      onClick={openViewAll}
                      data-gtm-category={gtmCategory}
                      data-gtm-label={data.gtmLabel}
                      data-gtm-action="click"
                    >
                      {data.name}
                    </li>
                  )
                }
                return (
                  <li
                    key={i}
                    className={cx(styles.list_item, {
                      [styles.blue_text]: selectView === data.slug,
                    })}
                    onClick={() => openView(data.slug)}
                    data-gtm-category={gtmCategory}
                    data-gtm-label={data.gtmLabel}
                    data-gtm-action="click"
                  >
                    {data.name}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className={styles.applications}>
            <div className={styles.application_container}>
              {ALL_APPLICATIONS_CARD.map((data, i) => {
                return (
                  <ApplicationCard
                    key={i}
                    {...data}
                    viewAll={viewAll}
                    active={selectView}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
