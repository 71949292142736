// @flow

export type ApplicationCardProps = {|
  header: string,
  slug: string,
  items: Array<{|
    text: string,
    gtmLabel: string,
    url: string,
    trueLink?: boolean,
    color: string,
    workstation?: boolean,
    children: string,
  |}>,
|}

export const BLACK: 'black' = 'black'
export const BLUE: 'blue' = 'blue'
export const ORANGE: 'orange' = 'orange'
export const LIGHT_BLUE: 'light-blue' = 'light-blue'
export const LIGHT_CYAN: 'light-cyan' = 'light-cyan'
export const GRAY: 'gray' = 'gray'
export const GREEN: 'green' = 'green'
export const gtmCategory: 'allapps' = 'allapps'

const CONTACT_US: 'Contact us' = 'Contact us'
const LEARN_MORE: 'Learn more' = 'Learn more'

export const ALL_APPLICATIONS_CARD: Array<ApplicationCardProps> = [
  {
    header: 'Sample Preparation',
    slug: 'sample-preparation',
    items: [
      {
        text: 'Cherrypicking',
        url: '/automated-cherrypicking/',
        trueLink: true,
        color: BLACK,
        children: LEARN_MORE,
        gtmLabel: 'cherrypicking',
      },
      {
        text: 'Compound Serial Dilution',
        url: 'https://protocols.opentrons.com/protocol/customizable_serial_dilution_ot2/',
        trueLink: false,
        color: BLACK,
        children: LEARN_MORE,
        gtmLabel: 'comp-serial-dilution',
      },
      {
        text: 'Microplate Filling',
        url: '/contact/',
        trueLink: true,
        color: BLACK,
        children: CONTACT_US,
        gtmLabel: 'micro-plate-filling',
      },
      {
        text: 'Normalization',
        url: '/automated-dna-normalization/',
        trueLink: true,
        color: BLACK,
        children: LEARN_MORE,
        gtmLabel: 'normalization',
      },
      {
        text: 'Plate Mapping',
        url: '/contact/',
        trueLink: true,
        color: BLACK,
        children: CONTACT_US,
        gtmLabel: 'plate-mapping',
      },
      {
        text: 'Sample Aliquoting',
        url: '/contact/',
        trueLink: true,
        color: BLACK,
        children: CONTACT_US,
        gtmLabel: 'sample-aliquoting',
      },
      {
        text: 'Serial Dilution',
        url: '/automated-serial-dilution/',
        trueLink: true,
        color: BLACK,
        children: LEARN_MORE,
        gtmLabel: 'serial-dilution',
      },
      {
        text: 'Tube Filling',
        url: '/contact/',
        trueLink: true,
        color: BLACK,
        children: CONTACT_US,
        gtmLabel: 'tube-filling',
      },
    ],
  },
  {
    header: 'NGS',
    slug: 'ngs',
    items: [
      {
        text: 'NGS Library Prep, Quantification & Normalization',
        url: 'https://insights.opentrons.com/ngs-automation/',
        trueLink: true,
        color: GREEN,
        children: LEARN_MORE,
        gtmLabel: 'full-library-prep-amp',
      },
      {
        text: 'NGS Workstation',
        url: '/workstations/expanded-ngs-workstation/',
        trueLink: true,
        color: GREEN,
        workstation: true,
        children: 'Buy workstation',
        gtmLabel: 'workstation-library-prep',
      },
    ],
  },
  {
    header: 'PCR',
    slug: 'pcr',
    items: [
      {
        text: 'Master Mix Prep',
        url: '/contact/',
        trueLink: true,
        color: ORANGE,
        children: CONTACT_US,
        gtmLabel: 'master-mix-prep',
      },
      {
        text: 'PCR Amplification',
        url: '/contact/',
        trueLink: true,
        color: ORANGE,
        children: CONTACT_US,
        gtmLabel: 'pcr-amp',
      },
      {
        text: 'PCR Cleanup',
        url: '/contact/',
        trueLink: true,
        color: ORANGE,
        children: CONTACT_US,
        gtmLabel: 'pcr-cleanup',
      },
      {
        text: 'PCR Prep (no amplification)',
        url: '/pcr-sample-prep/',
        trueLink: true,
        color: ORANGE,
        children: LEARN_MORE,
        gtmLabel: 'pcr-prep',
      },
      {
        text: 'qPCR Prep (no amplification)',
        url: '/pcr-sample-prep/',
        trueLink: true,
        color: ORANGE,
        children: LEARN_MORE,
        gtmLabel: 'qpcr-prep',
      },
    ],
  },
  {
    header: 'Nucleic Acid Extraction + Purification',
    slug: 'nucleic-acid-extraction-purification',
    items: [
      {
        text: 'NGS Cleanup and Size Selection',
        url: '/contact/',
        trueLink: true,
        color: BLUE,
        children: CONTACT_US,
        gtmLabel: 'ngs-cleanup',
      },
      {
        text: 'Nucleic Acid Extraction Workstation',
        url: '/workstations/automated-nucleic-acid-extraction/',
        trueLink: true,
        color: BLUE,
        workstation: true,
        children: 'Buy workstation',
        gtmLabel: 'workstation-ngs',
      },
      {
        text: 'Nucleic Acid Extraction',
        url: '/contact/',
        trueLink: true,
        color: BLUE,
        children: CONTACT_US,
        gtmLabel: 'na-extraction',
      },
      {
        text: 'Nucleic Acid Purification',
        url: '/nucleic-acid-cleanup/',
        trueLink: true,
        color: BLUE,
        children: LEARN_MORE,
        gtmLabel: 'na-purification',
      },
      {
        text: 'Plasmid Purification',
        url: '/contact/',
        trueLink: true,
        color: BLUE,
        children: CONTACT_US,
        gtmLabel: 'plasmid-purification',
      },
    ],
  },
  {
    header: 'Protein Prep',
    slug: 'protein-prep',
    items: [
      {
        text: 'BCA Assay',
        url: '/contact/',
        trueLink: true,
        color: ORANGE,
        children: CONTACT_US,
        gtmLabel: 'bca-assay',
      },
      {
        text: 'Bradford Assay',
        url: '/contact/',
        trueLink: true,
        color: ORANGE,
        children: CONTACT_US,
        gtmLabel: 'bradford-assay',
      },
      {
        text: 'ELISA',
        url: 'https://protocol-delivery.protocols.opentrons.com/categories/Proteins%20%26%20Proteomics/ELISA/',
        trueLink: false,
        color: ORANGE,
        children: LEARN_MORE,
        gtmLabel: 'elisa',
      },
      {
        text: 'MALDI Plate Spotting',
        url: '/contact/',
        trueLink: true,
        color: ORANGE,
        children: CONTACT_US,
        gtmLabel: 'maldi-plate-spotting',
      },
      {
        text: 'Protein Crystallization Screens',
        url: '/contact/',
        trueLink: true,
        color: ORANGE,
        children: CONTACT_US,
        gtmLabel: 'protein-cystalization',
      },
    ],
  },
  {
    header: 'DNA Assembly',
    slug: 'dna-assembly',
    items: [
      {
        text: 'BASIC Assembly',
        url: '/contact/',
        trueLink: true,
        color: LIGHT_BLUE,
        children: CONTACT_US,
        gtmLabel: 'basic-assembly',
      },
      {
        text: 'MoClo Assembly',
        url: '/contact/',
        trueLink: true,
        color: LIGHT_BLUE,
        children: CONTACT_US,
        gtmLabel: 'moclo-assembly',
      },
      {
        text: 'Golden Gate Assembly',
        url: '/contact/',
        trueLink: true,
        color: LIGHT_BLUE,
        children: CONTACT_US,
        gtmLabel: 'goldengate-assembly',
      },
    ],
  },
  {
    header: 'Cell Prep',
    slug: 'cell-prep',
    items: [
      {
        text: 'Agar Plate Spotting',
        url: '/contact/',
        trueLink: true,
        color: GRAY,
        children: CONTACT_US,
        gtmLabel: 'agar-plate-spotting',
      },
      {
        text: 'Cell Seeding',
        url: '/contact/',
        trueLink: true,
        color: GRAY,
        children: CONTACT_US,
        gtmLabel: 'cell-seeding',
      },
      {
        text: 'Cell Splitting',
        url: '/contact/',
        trueLink: true,
        color: GRAY,
        children: CONTACT_US,
        gtmLabel: 'cell-splitting',
      },
      {
        text: 'Cytotoxicity Screens',
        url: '/contact/',
        trueLink: true,
        color: GRAY,
        children: CONTACT_US,
        gtmLabel: 'cytotoxicity-screening',
      },
      {
        text: 'Media Exchange',
        url: '/contact/',
        trueLink: true,
        color: GRAY,
        children: CONTACT_US,
        gtmLabel: 'media-exchange',
      },
      {
        text: 'Trypsinization',
        url: '/contact/',
        trueLink: true,
        color: GRAY,
        children: CONTACT_US,
        gtmLabel: 'trypsinization',
      },
    ],
  },
  {
    header: 'EUA Diagnostics',
    slug: 'eua-diagnostics',
    items: [
      {
        text: 'COVID-19 Diagnostics',
        url: 'https://blog.opentrons.com/how-to-use-opentrons-to-test-for-covid-19/',
        trueLink: false,
        color: LIGHT_CYAN,
        children: LEARN_MORE,
        gtmLabel: 'covid-19-diagnostics',
      },
    ],
  },
]

const SAMPLE_PREPARATION = 'sample-preparation'
const PCR = 'pcr'
const NAER = 'nucleic-acid-extraction-purification'
const NGS = 'ngs'
const PROTEIN_PREP = 'protein-prep'
const DNA_ASSEMBLY = 'dna-assembly'
const CELL_PREP = 'cell-prep'
const EUA_DIAGNOSTICS = 'eua-diagnostics'

export type ListProps = {|
  name: string,
  slug?: string,
  allView?: boolean,
  gtmLabel: string,
|}

export const LIST_ITEMS_DATA: Array<ListProps> = [
  {
    name: 'View all',
    allView: true,
    gtmLabel: 'side-nav-view-all',
  },
  {
    name: 'Sample Prep',
    slug: SAMPLE_PREPARATION,
    gtmLabel: 'side-nav-sample-prep',
  },
  {
    name: 'NGS',
    slug: NGS,
    gtmLabel: 'side-nav-ngs-library-prep',
  },
  {name: 'PCR', slug: PCR, gtmLabel: 'pcr'},
  {
    name: 'Nucleic Acid Extraction + Purification',
    slug: NAER,
    gtmLabel: 'side-nav-na-purification',
  },
  {
    name: 'Protein Prep',
    slug: PROTEIN_PREP,
    gtmLabel: 'side-nav-protein-prep',
  },
  {
    name: 'DNA Assembly',
    slug: DNA_ASSEMBLY,
    gtmLabel: 'side-nav-dna-assembly',
  },
  {
    name: 'Cell Prep',
    slug: CELL_PREP,
    gtmLabel: 'side-nav-cell-prep',
  },
  {
    name: 'EUA Diagnostics',
    slug: EUA_DIAGNOSTICS,
    gtmLabel: 'side-nav-eua-diagnostics',
  },
]
